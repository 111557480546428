// API
import BaseAPI from './base';

/**
 * Implement Office API
 */
export default class OfficeAPI extends BaseAPI {
  constructor() {
    super('offices');
  }

  /**
   * Get available offices within a postal code area.
   * Obtenga oficinas disponibles dentro de un área de código postal.
   */
  availableOffices(data) {
    return this.request.post(`${this.baseURI}/available-offices`, data);
  }

  /**
   * Get available all offices.
   * Poner a disposición todas las oficinas.
   */
  allOffices(params) {
    return this.request.get('v1/office-all/', { params });
  }

  officeFilter(id) {
    return this.request.get(`v1/office-all/?office=${id}`);
  }

  /**
   * Save office's logo.
   * Guarde el logotipo de la oficina.
   */
  logo(id, data) {
    return this.request.post(`${this.baseURI}/${id}/logo`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
   * Get office's products.
   * Obtenga los productos de la oficina.
   */
  products(id, params) {
    return this.request.get(`${this.baseURI}/${id}/products`, { params });
  }

  /**
   * Verify if name is available.
   * Verifique si el nombre está disponible.
   */
  verifyName(data) {
    return this.request.post(`${this.baseURI}/verify-name`, data);
  }
}
