// API
import BaseAPI from './base';

/**
 * Implement Order API
 */
export default class OrderAPI extends BaseAPI {
  constructor() {
    super('orders');
  }

  /**
   * Get all operations made on order.
   * Obtener todas las operaciones realizadas en orden.
   */
  tracking(id, params = {}) {
    return this.request.get(`${this.baseURI}/${id}/tracking`, { params });
  }

  /**
   * Get available operations for order.
   * Obtener operaciones disponibles para ordenar.
   */
  availableOperations(id, params = {}) {
    return this.request.get(`${this.baseURI}/${id}/available-operations`, { params });
  }

  /**
   * Get operation catalogue.
   * Obtener catálogo de operaciones.
   */
  operations(params = {}) {
    return this.request.get(`${this.baseURI}/operations`, { params });
  }

  /**
   * Get Mercado Pago preference ID for checkout button.
   * Obtenga ID de preferencia de Mercado Pago para el botón de pago.
   */
  getPreferenceId(data) {
    return this.request.post(`${this.baseURI}/preference`, data);
  }

  /**
   * Process payment.
   * Procesar pago.
   */
  processPayment(data) {
    return this.request.post(`${this.baseURI}/process-payment`, data);
  }

  /**
   * Save operation for order's tracking.
   * Guardar operación para seguimiento de pedidos.
   */
  saveOperation(id, data) {
    return this.request.post(`${this.baseURI}/${id}/save-operation`, data);
  }

  /**
   * Get order report.
   * Obtener informe de pedido.
   */
  orderReport(params = {}) {
    return this.request.get(`${this.baseURI}/order-report`, { params });
  }

  /**
   * Get order report.
   * Obtener informe de pedido.
   */
  report(params = {}) {
    return this.request.get(`${this.baseURI}/report`, { params });
  }

  /**
   * Get order summary.
   * Obtener resumen del pedido.
   */
  summary(params = {}) {
    return this.request.get(`${this.baseURI}/summary`, { params });
  }
}
